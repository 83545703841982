import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';

import { Image, Link } from 'components';
import { Check, Filter as FilterSvg } from 'icons';

import config from 'config.js';

import './Filter.scss';
import { useLocalStorageState } from 'helpers';

const DEFAULT_FILTERS = {
  date: '2025-01-14',
  leagues: {
    Worlds: true,
    MSI: true,
    FS: true,
    LEC: true,
    LCK: true,
    LPL: true,
    LCP: true,
    LTA: true,
    'LTA North': true,
    'LTA South': true,
    EUM: true,
    LFL: true,
    NLC: true,
    SL: true,
    TCL: true,
  },
};

const enabledLeagues = Object.values(config.default.leagues).reduce(
  (acc, cat) => acc.concat(cat.filter((l) => !l.disabled)),
  []
);

const FilterContext = React.createContext();

export const useFilter = () => useContext(FilterContext);

export const FilterProvider = ({ children }) => {
  const [opened, setOpened] = useState(false);

  const [lastForcedCheck, setLastForcedCheck] = useLocalStorageState(
    'piltoverpost.filter.leagues.lastforcedcheck',
    DEFAULT_FILTERS.date
  );

  const [checkedLeagues, setLeagues] = useLocalStorageState(
    'piltoverpost.filter.leagues',
    DEFAULT_FILTERS.leagues,
    true
  );

  // Remove disabled leagues
  const leagues = Object.fromEntries(
    Object.entries(checkedLeagues).filter(([key]) => enabledLeagues.find((l) => l.code === key))
  );

  useEffect(() => {
    if (isBefore(parseISO(lastForcedCheck), parseISO(DEFAULT_FILTERS.date))) {
      setLeagues((prev) => ({ ...prev, ...DEFAULT_FILTERS.leagues }));
      setLastForcedCheck(DEFAULT_FILTERS.date);
    }
  }, [lastForcedCheck, setLeagues, setLastForcedCheck]);

  const setLeague = useCallback(
    (code, checked) => setLeagues((prev) => ({ ...prev, [code]: checked })),
    [setLeagues]
  );

  const val = useMemo(
    () => ({
      leagues,
      setLeague,
      opened,
      openModal: () => setOpened(true),
      closeModal: () => setOpened(false),
    }),
    [leagues, setLeague, opened, setOpened]
  );

  return <FilterContext.Provider value={val}>{children}</FilterContext.Provider>;
};

const totalCount = Object.values(config.default.leagues).reduce(
  (acc, leagues) => acc.concat(leagues.filter((l) => !l.disabled)),
  []
).length;

const Filter = () => {
  const { leagues: checked, setLeague, opened, openModal, closeModal } = useFilter();

  const checkedCount = Object.values(checked).filter((l) => l).length;

  return (
    <>
      <button className="app-filter" onClick={openModal}>
        <FilterSvg /> leagues{' '}
        <span className="count">
          ({checkedCount}/{totalCount})
        </span>
      </button>
      {opened && (
        <div className="modal-wrapper">
          <div className="modal-hover" onClick={closeModal} />
          <div className="modal">
            <h1>
              Filter
              <button onClick={closeModal}>
                <span role="img" aria-label="Close">
                  ✖️
                </span>
              </button>
            </h1>
            <div className="modal-body">
              {Object.entries(config.default.leagues).map(([category, leagues]) => (
                <div key={category} className="leagues">
                  {leagues.map(({ disabled, name, code, image, region }) => (
                    <label
                      key={code}
                      className={cn('league', { checked: checked[code], disabled })}
                    >
                      <div className="left">
                        <Image src={`images/leagues/${image}`} />
                        <span className="code">{region === 'International' ? name : code}</span>
                        {region !== 'International' && <span className="region">{region}</span>}
                      </div>
                      {disabled ? (
                        <small style={{ fontVariant: 'small-caps', fontSize: '11px' }}>
                          <Link to="/faq#why-unavailable">unavailable</Link>
                        </small>
                      ) : (
                        <div className="check">
                          <input
                            type="checkbox"
                            checked={checked[code] || false}
                            onChange={(e) => setLeague(code, e.target.checked)}
                          />
                          <Check />
                        </div>
                      )}
                    </label>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Filter;
