import React from 'react';
import cn from 'classnames';

import { Link } from 'components';

import './Faq.scss';
import { Helmet } from 'react-helmet-async';

const questions = {
  'what-is-the-piltover-post': {
    title: 'What is The Piltover Post?',
    content: (
      <p>
        <b>The Piltover Post</b> is a live ticker and live gold-diff graph. It allows you to{' '}
        <b>read</b> what happens in LoL Esports matches, without the need to watch the live streams.
      </p>
    ),
  },
  'why-is-it-named-the-piltover-post': {
    title: 'Why is it named "The Piltover Post"?',
    content: (
      <p>
        <Link to="https://universe.leagueoflegends.com/en_US/region/piltover/" external>
          Piltover
        </Link>{' '}
        is a region in League of Legends. As a thriving and progressive city, Piltover is greatly
        invested in the broadcasting of events all over Runeterra. The Piltover Post was established
        in 982 AN with the intent to become the prime source of information for fellow citizens of
        the Valoran.
      </p>
    ),
  },
  'found-a-bug': {
    title: 'I found a bug, where can I report it?',
    content: (
      <p>
        Oh no! We're sorry that you found a bug here. Please send us a message <Link twitter /> or{' '}
        <Link email />. We'll try to fix it soon! 🙏
      </p>
    ),
  },
  'is-the-piltover-post-free': {
    title: 'Is The Piltover Post free?',
    content: (
      <p>
        <b>For the moment, yes!</b> And we'll try to keep it this way, but the hosting and engine
        cost us a lot.
        <br />
        If you want to help us, please share The Piltover Post with your friends!
        {/*<br />
        If you want to support us to build and maintain The Piltover Post,{' '}
        <b>
          please consider donating using our <Link patreon />
        </b>
    !*/}
      </p>
    ),
  },
  'why-unavailable': {
    title: 'Why is [LPL/LCL/LLA/etc] not available on The Piltover Post?',
    content: (
      <>
        <p>
          At the root of any application is <b>the data</b>. We heavily rely on external apis to
          provide this data.
          <br />
          Unfortunately, those external apis do not provide data for all leagues. Therefore, we
          cannot provide you with a live-ticker for some leagues.
        </p>
        {/* <p>
          <br />
          <br />
          However: if we receive enough support on our <Link patreon />, we will be able to
          subscribe to a paid API (500€+) that would give us access to the necessary data.
          <br />
          So, you know what to do! 😬
    </p> */}
      </>
    ),
  },
  'who-wins-world': {
    title: 'Who do you think will win Worlds?',
    content: <p>Fnatic. Of course.</p>,
  },
};

/* const ThankYou = () => {
  return (
    <section>
      <h3>
        Awesome patrons on <Link patreon />
      </h3>
      <div style={{ padding: '0 1em' }}>
        <p>
          Thank you to everyone helping us develop and maintain The Piltover Post, making it the
          best LoL Esport live-ticker!
        </p>
        <ul>
          <li>Lautrec</li>
        </ul>
      </div>
    </section>
  );
}; */

const Faq = ({ location }) => {
  const { hash } = location;

  const target = hash.replace('#', '');

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://piltoverpost.gg/faq" />
      </Helmet>
      <main id="faq">
        <h1>Frequently Asked Questions</h1>
        {Object.entries(questions).map(([key, { title, content }]) => (
          <details
            key={key}
            id={key}
            open={!target || !questions[target] || target === key}
            className={cn({ targeted: target === key })}
          >
            <summary>{title}</summary>
            {content}
          </details>
        ))}
        {/* <hr />
        <ThankYou /> */}
      </main>
    </>
  );
};

export default Faq;
